define(['app', 'siteObj'], function(app, siteObj) {

  class CardScroller {

    constructor() {
      this.init = this.init.bind(this);
      this.getScrollTo = this.getScrollTo.bind(this);
      this.scrollTo = this.scrollTo.bind(this);
      this.isElementInView = this.isElementInView.bind(this);
      this.highlightNavBullets = this.debounce(this.highlightNavBullets.bind(this));
      this.previous = this.debounce(this.previous.bind(this));
      this.next = this.debounce(this.next.bind(this));
      this.getClassNamePrefix = this.getClassNamePrefix.bind(this);
      this.checkForScroll = this.checkForScroll.bind(this);
      this.props = {
        currentSlide1: siteObj.props.cardScroller.currentSlide1,
        currentSlide2: siteObj.props.cardScroller.currentSlide2,
        slide: siteObj.props.cardScroller.slide
      };
    }

    getClassNamePrefix() {
      // this is to prevent the user from entering a class name that is not valid
      const pattern = /^[a-zA-Z0-9_-]+$/;

      // check if the element has a data attribute for the class name prefix
      if (!this.element.hasAttribute('data-card-scroller-target-class')) {
        return null;
      }

      // set the data attribute value to a variable
      const attributeValue = this.element.getAttribute('data-card-scroller-target-class');

      // check if the data attribute value is empty or null and does not match the pattern
      if (attributeValue === '' || attributeValue === null  || !pattern.test(attributeValue)) {
        return null;
      }

      // return the data attribute value
      return attributeValue;
    }

    init(element) {
      this.element = element;
      this.isRightToLeft = document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl'
      
      // set the classNamePrefix, if null is returned, then the component will not initialize
      const classNamePrefix = this.getClassNamePrefix();

      // stop if the classNamePrefix is null
      if (classNamePrefix === null) {
        return;
      }

      const classNames = {
        jsLoaded: `${classNamePrefix}-jsLoaded`,
        scrollContainer: `${classNamePrefix}_scrollContainer`,
        cards: `${classNamePrefix}_cards`,
        card: `${classNamePrefix}_card`,
        navBullet: `${classNamePrefix}_navBullet`,
        navBulletActive: `${classNamePrefix}_navBullet-active`,
        previousButton: `${classNamePrefix}_previousButton`,
        nextButton: `${classNamePrefix}_nextButton`,
        navBullets: `${classNamePrefix}_navBullets`
      };

      this.classNames = {
        card: `${classNamePrefix}_card`,
        cards: classNames.cards,
        navBullet: classNames.navBullet,
        navBulletActive: classNames.navBulletActive,
        navHidden: `${classNamePrefix}_navHidden`,
        noScroll: `${classNamePrefix}_noScroll`
      };

      this.scrollContainer = element.querySelector(`.${classNames.scrollContainer}`);
      // check for an attribute to change justify-content
      if (this.element.getAttribute('data-switch') && this.element.getAttribute('data-switch') === 'noScrollCentreCards') {
        this.checkForScroll();
      }
      this.bulletElements = Array.from(element.getElementsByClassName(classNames.navBullet));
      this.bulletElements.forEach((bulletElement, index) => {
        bulletElement.addEventListener('click', this.getScrollTo(index));
      });

      this.prevButton = element.querySelector(`.${classNames.previousButton}`);
      if (this.prevButton) {
        this.prevButton.addEventListener('click', this.previous);
      }

      this.nextButton = element.querySelector(`.${classNames.nextButton}`);
      if (this.nextButton) {
        this.nextButton.addEventListener('click', this.next);
      }

      this.navBullets = element.querySelector(`.${classNames.navBullets}`);

      this.scrollContainer.addEventListener('scroll', this.highlightNavBullets);
      window.addEventListener('resize', this.highlightNavBullets);
      
      this.cards = element.querySelector(`.${classNames.cards}`);
      this.cardElements = Array.from(this.element.querySelectorAll(`.${this.classNames.card}`));
      app.element.addClass(classNames.jsLoaded, this.element);
     
      // maybe use a logical and here
      this.bulletElements? this.highlightNavBullets() : undefined;
    }

    checkForScroll() {
      // get width of the scroll container
      const scrollContainerWidth = this.scrollContainer.offsetWidth;
      const allChildCards = this.element.querySelectorAll(`.${this.classNames.card}`);
      const cardsParent = this.element.querySelector(`.${this.classNames.cards}`);

      if (!scrollContainerWidth || !allChildCards.length) return false;

      // get the total width of all the child cards
      const totalWidthOfAllChildCards = [...allChildCards].reduce((acc, card) => { acc += card.offsetWidth; return acc; }, 0);
      const justifyContainer = totalWidthOfAllChildCards <= scrollContainerWidth;
      if (justifyContainer) {
        cardsParent.style.justifyContent = 'center';
      } else {
        cardsParent.style.justifyContent = 'flex-start';
      }
    }

    getScrollTo(scrollIndex) {
      return () => {
        this.scrollTo(scrollIndex);
      };
    }

    previous() {
      if ( this.scrollContainer.scrollLeft === 0) {
        this.scrollTo(this.cardElements.length - 1);
      } else {
        const cardWidth = this.cardElements[0].offsetWidth;
        const numOfCardsInView = Math.max(Math.floor(this.cards.offsetWidth/cardWidth), 1);
        let activeBulletIndex;
        for (let i = 0; i < this.bulletElements.length; i++) {
          if (this.bulletElements[i].classList.contains(this.classNames.navBulletActive)) {
            activeBulletIndex = i;
            break;
          }
        }
        this.scrollTo(activeBulletIndex - numOfCardsInView);
      }
    }

    next() {
      let offsetWidth;
      let scrollWidth;
      const isRightToLeft = this.isRightToLeft;
      
      offsetWidth = isRightToLeft ? -this.scrollContainer.offsetWidth : this.scrollContainer.offsetWidth;
      scrollWidth = isRightToLeft ? -this.scrollContainer.scrollWidth : this.scrollContainer.scrollWidth;
      
      if (this.scrollContainer.scrollLeft + offsetWidth === scrollWidth) {
        this.scrollTo(0);
      } else {
        const cardWidth = this.cardElements[0].offsetWidth;
        const numOfCardsInView = Math.max(Math.floor(this.cards.offsetWidth / cardWidth), 1);
        let activeBulletIndex;
        for (let i = 0; i < this.bulletElements.length; i++) {
          if (this.bulletElements[i].classList.contains(this.classNames.navBulletActive)) {
            activeBulletIndex = i;
            break;
          }
        }
        this.scrollTo(activeBulletIndex + numOfCardsInView);
      }
    }

    debounce (functionToDebounce) {
      let timeOutId;
      return function() {
        if(timeOutId) {
          clearTimeout(timeOutId);
        }
        timeOutId = setTimeout(() => {
          functionToDebounce();
        },500);
      }
    }

    scrollTo(scrollIndex) {
      const scrollContainer = this.scrollContainer;
      const cardWidth = this.cardElements[0].offsetWidth;
      let scrollAmount;
      const isRightToLeft = this.isRightToLeft;

      scrollAmount = isRightToLeft ? scrollAmount =  cardWidth * scrollIndex + this.scrollContainer.scrollLeft : scrollAmount =  cardWidth * scrollIndex - this.scrollContainer.scrollLeft;

      const leftDirection = scrollAmount > 0;
      scrollAmount = (scrollAmount > 0) ? scrollAmount : -scrollAmount;
      const scrollIncrement = scrollAmount / 30;

      let id;
      
      const animateFrame = () => {

        if (this.isRightToLeft){
          if (scrollAmount <= 0 ) {
            clearInterval(id);
          } else if (leftDirection) {
            scrollAmount -= scrollIncrement;
            scrollContainer.scrollLeft -= scrollIncrement;
          } else {
            scrollAmount -= scrollIncrement;
            scrollContainer.scrollLeft += scrollIncrement;
          }
        } else {
          if (scrollAmount <= 0 ) {
            clearInterval(id);
          } else if (leftDirection) {
            scrollAmount -= scrollIncrement;
            scrollContainer.scrollLeft += scrollIncrement;
          } else {
            scrollAmount -= scrollIncrement;
            scrollContainer.scrollLeft -= scrollIncrement;
          }
        }

      };
      id = setInterval(animateFrame, 10);
    }

    isElementInView(cardElement, containerLeft, containerRight) {
      let defaultMarginOffset = 16;
      let cardLeft = cardElement.offsetLeft - defaultMarginOffset;
      let cardRight = cardLeft + cardElement.clientWidth;
      const halfCardWidth = cardElement.clientWidth * .5;
      return (cardRight - halfCardWidth >= containerLeft && cardLeft <= containerRight - halfCardWidth );
    }

    highlightNavBullets() {
      
      if (this.bulletElements.length <= 0) { 
        return 
      }

      let containerLeft = this.scrollContainer.scrollLeft;
      let containerRight = containerLeft + this.scrollContainer.offsetWidth;
      let addNavHiddenClass = true;

      this.cardElements.forEach((card, index) => {
        const bulletElement = this.bulletElements[index];
        if(this.isElementInView(card, containerLeft, containerRight)) {
          app.element.addClass(this.classNames.navBulletActive, bulletElement);
          bulletElement.innerHTML = `<span class="visually-hidden">${this.props.currentSlide1} ${index+1} ${this.props.currentSlide2}</span>`;
        } else {
          app.element.removeClass(this.classNames.navBulletActive, bulletElement);
          bulletElement.innerHTML = `<span class="visually-hidden">${this.props.slide} ${index+1}</span>`;
          addNavHiddenClass = false;
        }
      });
      this.switchNavVisibility(addNavHiddenClass);
    }

    switchNavVisibility(addNavHiddenClass) {
      if(!this.prevButton || !this.nextButton || !this.cardElements) {
        return;
      }
      let action = addNavHiddenClass ? 'add' : 'remove';
      this.navBullets.classList[action](this.classNames.navHidden);
      this.prevButton.classList[action](this.classNames.navHidden);
      this.nextButton.classList[action](this.classNames.navHidden);
    }
  }

  return CardScroller;
});
